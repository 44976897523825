@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-event-image {
  background-image: url("../src/assets/card1.avif");
  background-size: cover;
  background-position: center;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.264);
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.254);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.41);
}

/* Calendar container styles */
.react-calendar {
  width: 350px;
  max-width: 100%;
  background: black !important;
  border: none !important;
  line-height: 1.125em;
  opacity: 1 !important;
  color: #414449 !important;
  border-radius: 6px;
}

/* Styles for month view day names (SUN, MON, etc.) */
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
  color: white; /* Change this line to set the desired color */
}

/* Styles for month view day names and navigation buttons */
.react-calendar__month-view__weekdays__weekday,
.react-calendar__navigation button {
  padding: 0.5em;
  color: white; /* Change this line to set the desired color */
  background: none; /* Remove background on hover */
}

/* Hover styles for enabled navigation buttons */
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: transparent !important; /* Remove background on hover/focus */
}
/* Hover styles for navigation buttons */
.react-calendar__navigation button:hover,
.react-calendar__navigation button:focus {
  background-color: transparent !important; /* Remove background on hover/focus */
}

/* Calendar styles for double view */
.react-calendar--doubleView {
  width: 700px;
}

/* Styles for double view container */
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

/* Styles for double view calendar components */
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

/* Reset box-sizing for calendar elements */
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* Button styles within the calendar */
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

/* Hover styles for enabled buttons */
.react-calendar button:enabled:hover {
  cursor: pointer;
}

/* Navigation styles */
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}

/* Navigation button styles */
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

/* Hover and focus styles for enabled navigation buttons */
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

/* Disabled navigation button styles */
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}

/* Weekday styles in month view */
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

/* Styles for individual weekdays in month view */
.react-calendar__month-view__weekdays__weekday {
  color: white !important;
  padding: 0.5em;
}

/* Styles for week numbers in month view */
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}

/* Styles for individual tiles in month view week numbers */
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}

/* Styles for weekend days in month view */
.react-calendar__month-view__days__day--weekend {
  color: #414449 !important;
}

/* Styles for neighboring month days in month view */
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

/* Styles for year, decade, and century view tiles */
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

/* Styles for individual tiles */
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}

/* Disabled tile styles */
.react-calendar__tile:disabled {
  background-color: transparent !important;
  cursor: not-allowed !important;
  opacity: 0.7 !important;
}

/* Hover styles for all tiles */
.react-calendar__tile:hover,
.react-calendar__tile:enabled:hover {
  border: 1px solid #006edc !important;
  border-radius: 5px !important;
  background: transparent !important;
  color: white !important;
}

/* Active tile styles */
.react-calendar__tile--active {
  background: #006edc !important;
  color: white !important;
  border-radius: 4px !important; /* Adjust the border-radius value as needed */
  transition: background 0.3s ease; /* Add a smooth transition effect */
}

/* Current date (today) tile styles */
.react-calendar__tile--now {
  background: #1363ff !important;
}

/* Hover and focus styles for current date (today) tile */
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}

/* Tile with active range styles */
.react-calendar__tile--hasActive {
  background: #76baff;
}

/* Hover and focus styles for tile with active range */
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

/* Active tile styles */
.react-calendar__tile--active {
  background: #006edc;
  color: white;
}

/* Hover and focus styles for active tile */
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}

/* style for Month's name color white */
.react-calendar__navigation__label {
  color: white !important;
}

/* style for Current date (today) tile active color */
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

/* /// use events detail page /// */
.shadow-eventsDetailPage {
  transition: box-shadow 0.3s ease;
}
/* hover styling for Events in Popular Cities */
.shadow-eventsDetailPage:hover {
  box-shadow: 0 0px 5px 1px #206bff;
}

.shadow-eventsDetailPageVip {
  transition: box-shadow 0.3s ease;
}
/* hover styling for Events in Popular Cities */
.shadow-eventsDetailPageVip:hover {
  box-shadow: 0 0px 5px 1px #ffffff57;
}

.style_mainTitle__y3_rr.style_gradientToOrange__4cSBG {
  background: linear-gradient(90deg, #fff, #ffa41a);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

/* /// Venues Details Slider /// */
.slick-prev {
  display: flex !important;
  background: #fcc100 !important;
  /* opacity: 0.5; */
  width: 40px !important;
  height: 40px !important;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  z-index: 99;
}
.slick-prev:hover {
  opacity: 1 !important;
}

.slick-next {
  display: flex !important;
  background: #fcc100 !important;
  /* opacity: 0.5; */
  width: 40px !important;
  height: 40px !important;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  z-index: 99;
}
.slick-next:hover {
  opacity: 1 !important;
}

/* styling for Events in all Cities */
.allCities-shadow-customClass {
  border-radius: 10px;
  transition: box-shadow 0.3s ease;
}
/* hover styling for Events in al Cities */
.allCities-shadow-customClass:hover {
  box-shadow: 0 0px 5px 0.2px #b6b0b0;
}
/* styling for citiesWeAreIn-customClass */
.citiesWeAreIn-customClass {
  border-radius: 10px;
  transition: box-shadow 0.3s ease;
}
/* hover styling for citiesWeAreIn-customClass */
.citiesWeAreIn-customClass:hover {
  box-shadow: 0 0px 5px 0.2px #ffffffaf;
}
/* styling for Events in Popular Cities */
.shadow-customClass {
  transition: box-shadow 0.3s ease;
}
/* hover styling for Events in Popular Cities */
.shadow-customClass:hover {
  box-shadow: 0 0px 5px 1px #ffffffaf;
}

.style_mainTitle__y3_rr {
  font-size: 3xl;
  font-weight: normal;
  line-height: none;
}

.style_gradientToBlue__bfJnC {
  background: linear-gradient(90deg, #fff, #1463ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent; /* Set text color to transparent */
}

.style_mainTitle__y3_rr .style_gradientToOrange__4cSBG {
  background: linear-gradient(90deg, #fff, #ffa41a) !important;
  -webkit-background-clip: text !important;
  -moz-background-clip: text !important;
  -ms-background-clip: text !important;
  background-clip: text !important;
  color: transparent !important; /* Hides the original text */
}
.react-tel-input .form-control {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  position: relative;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  width: 100% !important;
  font-size: 0.9rem !important;
  color: white !important;
  background: transparent !important;
  border: none !important;
}

.flag-dropdown {
  background-color: transparent !important;
  border: none !important;
}

.css-13cymwt-control {
  background-color: black !important;
  border: 1px solid rgb(255 255 255 / 0.3) !important;
  color: white !important;
}
.css-1dimb5e-singleValue {
  color: white !important;
}
.css-t3ipsp-control {
  background-color: black !important;
}

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.stsearch-box input{
border: none !important;
}
.guZdik {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 25rem !important;
  min-width: 20rem !important;
  height: 150px !important;
  border: 2px !important;
  padding: 8px 16px 8px 8px;
  border-radius: 5px;
  cursor: pointer;
  -webkit-box-flex: 0;
}
.react-datetimerange-picker__wrapper {
  padding: 6px;
  border-radius: 5px;
}
.react-calendar{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: #0A0D16  !important;
}
.react-calendar button{
  color: white !important;
}
.react-calendar__navigation button:disabled {
  background-color: #494949 !important;
}
.rmdp-container {
  width: 100% !important;
}
.rmdp-input{
  width: 100% !important;
  background-color: #0A0D16;
  color: white;
  padding: 20px !important;
  border: 1px solid gray !important;
  
}
.datetime-input {
  background-color: #0A0D16 ;
}


::-webkit-calendar-picker-indicator {
  filter: invert(1);
}